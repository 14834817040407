import { slick } from 'slick-carousel';

document.addEventListener('DOMContentLoaded', (e) => {

    const blocks = document.querySelectorAll('.block--image-awards-carousel');
    const config = { rootMargin: '0px 0px', threshold: 0 };

    const block_image_carousel = {

        init: (block) => {
            block_image_carousel.slick(block);
        },
		
		// blockIntersection: (entries) => {
			
		// 	entries.forEach( entry => {
		// 		if ( entry.intersectionRatio > 0 ) {
		// 			blockObserver.unobserve( entry.target );
		// 		}
		// 	});		
		// },

		slick: (block) => {

            const target = block.querySelector('.image-carousel');

            const options = {
                slidesToShow: 5,
				slidesToScroll: 1,
                arrows: true,
                dots: false,
                autoplay: true,
				autoplaySpeed: 3000,
                infinite: true,
                variableWidth: false,
                centerMode: false,
                //centerPadding: '10%',
                pauseOnHover: true,
                appendArrows: '#' + block.id + ' .image-carousel-nav',
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 1,
							centerMode: true,
							variableWidth: true,
						}
					}
				]
            };
		
			let items = target?.querySelectorAll('.image');

			if (items && items.length > 1) {
				items.forEach((item, index) => {
					const transformClass = `transform-${((index * 2) % 5) + 1}`;
					item.classList.add(transformClass);
				});
	
				$(target).slick(options);

				
				const arrows = block.querySelectorAll('.image-carousel-nav .slick-arrow');
				arrows.forEach(arrow => {
					arrow.addEventListener('mouseenter', () => {
						$(target).slick('slickPause');
					});
					arrow.addEventListener('mouseleave', () => {
						$(target).slick('slickPlay');
					});
				});
			}
		},
		
		log: () => {
			console.log('test');
		}
	}

	// let blockObserver = new IntersectionObserver( block_testimonials.blockIntersection, config );

	blocks.forEach( block => {
		block_image_carousel.init(block);
	});
});

